<template>
  <v-switch
    v-if="$route.name === 'community-collections'"
    :value="isWorkspaceOnlySearch"
    :disabled="isActiveUserGuestInWorkspace"
    class="d-inline-block ml-2"
    label="Followed Only"
    inset
    @change="toggleIsWorkspaceOnlySearch" />
</template>

<script>
import {
  mapGetters, mapMutations, mapState,
} from 'vuex';

export default {
  name: 'SearchFilterWorkspaceOnly',
  computed: {
    ...mapState(['isWorkspaceOnlySearch']),
    ...mapGetters('Workspace', ['isActiveUserGuestInWorkspace']),
  },
  mounted() {
    if (this.isActiveUserGuestInWorkspace) {
      this.toggleIsWorkspaceOnlySearch(true);
    }
  },
  methods: {
    ...mapMutations(['toggleIsWorkspaceOnlySearch']),
  },
};
</script>